<template>
  <div class="constrain part contact-part">
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <div class="row-12 contact-block mt-16">
          <div class="md:col-4">
            <div class="flex flex-col items-center mb-8 md:mb-0">
              <Poi />
              <p class="text-center text-navy" v-html="payload.adresse" />
            </div>
          </div>
          <div class="md:col-4">
            <div class="flex flex-col items-center mb-8 md:mb-0">
              <Phone />
              <a class="text-navy" :href="'tel:' + payload.telefon">Tel.: {{ payload.telefon }}</a>
              <a class="text-navy" :href="'tel:' + payload.fax">Fax.: {{ payload.fax }}</a>
            </div>
          </div>
          <div class="md:col-4">
            <div class="flex flex-col items-center">
              <Mail />
              <a class="text-center text-navy" :href="'mailto:' + payload.email">{{ payload.email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Poi from '../../assets/images/poi.svg';
import Phone from '../../assets/images/phone.svg';
import Mail from '../../assets/images/email.svg';

export default {
  components: {
    Poi,
    Phone,
    Mail,
  },
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.contact-part {
 margin-top: -5rem;
    @screen md {
      margin-top: -7rem;
    }
    @screen lg {
      margin-top: -10rem;
    }
}

.contact-block {
  :deep(p) {
    line-height: 1.3;
  }
  p, a {
    line-height: 1.3;
  }
  a:hover {
    text-decoration: underline;
  }
  svg {
    height: px(35);
    width: px(35);
    color: var(--color-blue);
    margin-bottom: 1rem;
    @screen md {
      height: px(40);
      width: px(40);
      margin-bottom: 2rem;
    }
  }
}
</style>
